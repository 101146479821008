.dash-one-slider {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto; }

.brk-testimonials-dash-one {
  max-width: 970px;
  margin: 58px 15px 0;
  padding-top: 69px;
  position: relative;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  @media (max-width: 575px) {
    .brk-testimonials-dash-one {
      margin: 58px 0 0; } }
  .brk-testimonials-dash-one:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    width: 100%;
    height: 1px;
    background: url(../../img/hatch_1.png) repeat-x; }
  .brk-testimonials-dash-one__btn {
    position: absolute;
    right: 140px;
    top: 31px;
    margin: 0;
    z-index: 9;
    box-shadow: 0 5px 16px var(--brk-base-1); }
    @media (max-width: 479px) {
      .brk-testimonials-dash-one__btn {
        position: relative;
        top: auto;
        left: 15px;
        width: calc(100% - 30px);
        margin: 20px 0; } }
  .brk-testimonials-dash-one__img {
    position: absolute;
    width: 116px;
    height: 116px;
    border-radius: 50%;
    overflow: hidden;
    top: 0;
    left: 47px;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .brk-testimonials-dash-one__img {
        position: static;
        transform: none;
        margin: 0 auto 15px; } }
  .brk-testimonials-dash-one__info {
    flex-basis: 160px;
    min-width: 160px;
    text-align: center;
    padding-top: 10px; }
    @media (max-width: 767px) {
      .brk-testimonials-dash-one__info {
        flex-basis: 160px;
        min-width: 160px;
        padding-top: 0; } }
    @media (max-width: 575px) {
      .brk-testimonials-dash-one__info {
        flex-basis: 100%;
        min-height: 0; } }
    .brk-testimonials-dash-one__info .brk-rating {
      margin: 0 auto; }
    .brk-testimonials-dash-one__info h4 {
      font-size: 1rem;
      line-height: 20px;
      margin-top: 10px; }
  .brk-testimonials-dash-one__position {
    display: block;
    font-size: 0.875rem;
    line-height: 22px;
    color: #585858; }
  .brk-testimonials-dash-one__description {
    flex-basis: calc(100% - 215px);
    font-size: 1rem;
    line-height: 26px;
    padding-left: 12px;
    color: #585858; }
    @media (max-width: 767px) {
      .brk-testimonials-dash-one__description {
        flex-basis: calc(100% - 160px); } }
    @media (max-width: 575px) {
      .brk-testimonials-dash-one__description {
        flex-basis: 100%;
        margin-top: 15px;
        padding-left: 0; } }

.brk-testimonials-dash-two {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .brk-testimonials-dash-two .slick-track, .brk-testimonials-dash-two .swiper-container {
    margin-left: 0;
    margin-right: 0; }
  .brk-testimonials-dash-two .dash-two-pagination {
    flex-basis: 100px;
    min-width: 100px;
    height: 340px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 767px) {
      .brk-testimonials-dash-two .dash-two-pagination {
        flex-basis: 100%;
        min-width: 100%;
        height: 100px !important;
        margin: 0 auto;
        flex-direction: row;
        justify-content: center; } }
    .brk-testimonials-dash-two .dash-two-pagination-bullet {
      width: 0;
      height: 0;
      border-radius: 50%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: scale(0);
      position: relative;
      z-index: 1;
      transition: all .4s ease-in-out;
      cursor: pointer;
      top: 0 !important;
      left: 0 !important;
      outline: none; }
      .brk-testimonials-dash-two .dash-two-pagination-bullet:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border-radius: 50%;
        background-color: white;
        transition: all .4s ease-in-out; }
      .brk-testimonials-dash-two .dash-two-pagination-bullet-active {
        width: 64px;
        height: 64px;
        transform: scale(1.5625);
        z-index: 4; }
        .brk-testimonials-dash-two .dash-two-pagination-bullet-active:before {
          background-color: rgba(var(--white-rgb), 0); }
        .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev, .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next {
          width: 64px;
          height: 64px;
          z-index: 3;
          outline: none; }
          .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev:before, .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next:before {
            background-color: rgba(var(--white-rgb), 0.6); }
          .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev-prev, .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev-next, .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next-prev, .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next-next {
            width: 64px;
            height: 64px;
            z-index: 2; }
            .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev-prev:before, .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev-next:before, .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next-prev:before, .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next-next:before {
              background-color: rgba(var(--white-rgb), 0.9); }
        .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev {
          transform: translateY(-10px) scale(1.21875); }
          @media (max-width: 767px) {
            .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev {
              transform: translateX(-10px) scale(1.21875); } }
          .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev-prev {
            transform: translateY(-10px) scale(1); }
            @media (max-width: 767px) {
              .brk-testimonials-dash-two .dash-two-pagination-bullet-active-prev-prev {
                transform: translateX(-10px) scale(1); } }
        .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next {
          transform: translateY(10px) scale(1.21875); }
          @media (max-width: 767px) {
            .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next {
              transform: translateX(10px) scale(1.21875); } }
          .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next-next {
            transform: translateY(10px) scale(1); }
            @media (max-width: 767px) {
              .brk-testimonials-dash-two .dash-two-pagination-bullet-active-next-next {
                transform: translateX(10px) scale(1); } }
  .brk-testimonials-dash-two .dash-two-slider {
    flex-basis: calc(100% - 105px);
    margin-right: -20px; }
    @media (max-width: 767px) {
      .brk-testimonials-dash-two .dash-two-slider {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .brk-testimonials-dash-two__dots-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    z-index: 1;
    transition: all .4s ease; }
    .brk-testimonials-dash-two__dots-img:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .brk-testimonials-dash-two__dots-img img {
      width: 100%;
      height: 100%; }
  .brk-testimonials-dash-two__text-reviews {
    margin: 20px;
    min-height: 320px;
    text-align: left;
    padding: 45px 47px 45px 50px; }
    .brk-testimonials-dash-two__text-reviews:before, .brk-testimonials-dash-two__text-reviews:after {
      content: " ";
      display: table; }
    .brk-testimonials-dash-two__text-reviews:after {
      clear: both; }
  .brk-testimonials-dash-two__name, .brk-testimonials-dash-two__service {
    font-size: 1rem;
    line-height: 18px; }
  .brk-testimonials-dash-two__service {
    margin-left: 3px;
    color: #9f9f9f; }
  .brk-testimonials-dash-two__description {
    font-size: 1rem;
    line-height: 28px;
    margin-top: 24px;
    letter-spacing: -0.001em;
    color: #9f9f9f; }
  .brk-testimonials-dash-two__link-video {
    float: right;
    font-size: 1rem;
    line-height: 42px;
    margin-top: 27px;
    color: var(--brand-primary); }
    .brk-testimonials-dash-two__link-video span {
      display: inline-block;
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 21px;
      margin-right: 14px;
      background-color: var(--brand-primary);
      box-shadow: 0 5px 16px rgba(var(--brand-primary-rgb), 0.5); }
      .brk-testimonials-dash-two__link-video span i {
        position: absolute;
        top: 50%;
        left: calc(50% + 1px);
        transform: translate(-50%, -50%);
        font-size: 1.25rem;
        color: var(--white); }

.brk-testimonials-dash-three {
  position: relative; }
  .brk-testimonials-dash-three svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%); }
  .brk-testimonials-dash-three .swiper-slide {
    height: auto; }
  .brk-testimonials-dash-three .swiper-pagination-base .swiper-pagination-bullet {
    flex-basis: 10px;
    min-width: 10px;
    height: 10px;
    outline: none; }
  .brk-testimonials-dash-three .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px; }
  .brk-testimonials-dash-three .dash-three-slider {
    padding: 50px 0 0; }
    .brk-testimonials-dash-three .dash-three-slider .swiper-slide {
      border-radius: 7px;
      overflow: hidden;
      transition: all .3s ease-in-out; }
      @media (max-width: 991px) {
        .brk-testimonials-dash-three .dash-three-slider .swiper-slide {
          max-width: none; } }
      .brk-testimonials-dash-three .dash-three-slider .swiper-slide:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition-property: background-color, visibility, opacity;
        transition-duration: .3s;
        background-color: rgba(var(--white-rgb), 0.8); }
      .brk-testimonials-dash-three .dash-three-slider .swiper-slide.swiper-slide-active {
        transform: translateY(-10px);
        box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1); }
        @media (max-width: 767px) {
          .brk-testimonials-dash-three .dash-three-slider .swiper-slide.swiper-slide-active {
            transform: translateY(0); } }
        .brk-testimonials-dash-three .dash-three-slider .swiper-slide.swiper-slide-active:before {
          background-color: transparent;
          visibility: hidden;
          opacity: 0; }
    .brk-testimonials-dash-three .dash-three-slider_single {
      padding-top: 10px; }
      .brk-testimonials-dash-three .dash-three-slider_single .swiper-slide {
        box-shadow: none;
        background-color: #fff; }
        .brk-testimonials-dash-three .dash-three-slider_single .swiper-slide:before {
          background-color: transparent;
          visibility: hidden;
          opacity: 0; }
        .brk-testimonials-dash-three .dash-three-slider_single .swiper-slide.swiper-slide-active {
          box-shadow: none;
          background-color: #fff;
          transform: translateY(0); }
        .brk-bordered-theme .brk-testimonials-dash-three .dash-three-slider_single .swiper-slide {
          border-radius: var(--b-radius);
          overflow: hidden; }
  .brk-testimonials-dash-three__item {
    width: 100%;
    min-height: 280px;
    height: 100%;
    padding: 40px 40px 30px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start; }
    [dir='rtl'] .brk-testimonials-dash-three__item {
      text-align: right; }
  .brk-testimonials-dash-three__img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    float: left; }
    .brk-testimonials-dash-three__img img {
      max-width: 100%;
      max-height: 100%; }
  .brk-testimonials-dash-three__caption {
    width: calc(100% - 48px);
    padding-left: 19px; }
    [dir='rtl'] .brk-testimonials-dash-three__caption {
      padding-left: 0;
      padding-right: 19px; }
  .brk-testimonials-dash-three__desc {
    width: 100%;
    margin-top: 22px; }
  .brk-testimonials-dash-three_transparent {
    background-color: transparent; }
  .brk-testimonials-dash-three_dark .swiper-slide .brk-testimonials-dash-three__item {
    background-color: var(--brk-base-2); }
  .brk-testimonials-dash-three_dark .swiper-slide-active .brk-testimonials-dash-three__item {
    background-color: var(--secondary); }
  .brk-testimonials-dash-three_dark .brk-testimonials-dash-three__desc,
  .brk-testimonials-dash-three_dark .brk-testimonials-dash-three__name {
    color: #ffffff; }
  .brk-testimonials-dash-three_dark .brk-testimonials-dash-three__classes {
    color: rgba(255, 255, 255, 0.2); }
  .brk-testimonials-dash-three_dark .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.3); }
    .brk-testimonials-dash-three_dark .swiper-pagination-bullet-active {
      background-color: #fff; }

.brk-testimonials-dash-four {
  min-height: 406px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 120px;
  background-color: var(--white); }
  .brk-testimonials-dash-four .dash-four-pagination {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9; }
    .brk-testimonials-dash-four .dash-four-pagination-bullet {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin: 0 5px;
      background: no-repeat center center;
      background-size: cover;
      cursor: pointer;
      transition: all .3s ease-in-out;
      border: 2px solid var(--white);
      outline: none; }
      .brk-testimonials-dash-four .dash-four-pagination-bullet:hover, .brk-testimonials-dash-four .dash-four-pagination-bullet-active {
        width: 70px;
        height: 70px; }
    .brk-testimonials-dash-four .dash-four-pagination_large .dash-four-pagination-bullet {
      width: 48px;
      height: 48px;
      margin: 0 25px; }
      .brk-testimonials-dash-four .dash-four-pagination_large .dash-four-pagination-bullet:hover, .brk-testimonials-dash-four .dash-four-pagination_large .dash-four-pagination-bullet-active {
        width: 80px;
        height: 80px; }
  .brk-testimonials-dash-four__item i {
    margin-top: 42px;
    color: #e8e8e8; }
  .brk-testimonials-dash-four__title {
    margin-top: 25px;
    font-size: 1.125rem;
    line-height: 24px; }
  .brk-testimonials-dash-four__text {
    font-size: 0.875rem;
    line-height: 24px;
    max-width: 1000px;
    margin: 20px auto 0;
    padding: 0 15px;
    color: #88909c; }
  .brk-testimonials-dash-four__dots-img {
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid var(--white); }
    .brk-testimonials-dash-four__dots-img img {
      max-width: 100%;
      max-height: 100%; }
  .brk-testimonials-dash-four__layout {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    background: no-repeat center center;
    background-size: cover; }
    .brk-testimonials-dash-four__layout .before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(var(--brand-primary-rgb), 0.94); }
  .brk-testimonials-dash-four_transparent {
    background-color: transparent;
    box-shadow: none; }
    .brk-testimonials-dash-four_transparent .brk-testimonials-dash-four__layout {
      display: none; }
    .brk-testimonials-dash-four_transparent .brk-testimonials-dash-four__item i {
      background-color: #fff;
      padding: 14px;
      font-size: 36px;
      border-radius: 6px;
      color: var(--brk-base-2); }
    .brk-testimonials-dash-four_transparent .brk-testimonials-dash-four__item::after, .brk-testimonials-dash-four_transparent .brk-testimonials-dash-four__item::before {
      content: '';
      position: absolute;
      left: 0;
      top: 76px;
      width: calc(50% - 52px);
      height: 1px;
      background-color: rgba(255, 255, 255, 0.1); }
    .brk-testimonials-dash-four_transparent .brk-testimonials-dash-four__item::before {
      left: auto;
      right: 0; }
    .brk-testimonials-dash-four_transparent .brk-testimonials-dash-four__title,
    .brk-testimonials-dash-four_transparent .brk-testimonials-dash-four__text {
      color: #fff; }
  .brk-testimonials-dash-four_corner-pagination .dash-four-pagination {
    bottom: 0;
    transform: translate(-50%, 50%); }

.brk-testimonials-dash-five {
  position: relative; }
  .brk-testimonials-dash-five .swiper-wrapper {
    padding-top: 70px;
    padding-bottom: 30px; }
  .brk-testimonials-dash-five .swiper-slide {
    z-index: 1; }
    .brk-testimonials-dash-five .swiper-slide:before {
      content: '';
      position: absolute;
      left: -15px;
      top: 0;
      width: calc(100% + 30px);
      height: 100%;
      transition: .3s ease all;
      background-color: #ffffff; }
    .brk-testimonials-dash-five .swiper-slide:after {
      content: '';
      position: absolute;
      left: -15px;
      bottom: 0;
      height: 3px;
      width: calc(100% + 30px);
      background-image: linear-gradient(to right, var(--brand-primary) 0%, var(--secondary) 100%);
      opacity: 0;
      transition: .3s ease opacity, .3s ease bottom; }
    .brk-testimonials-dash-five .swiper-slide .brk-testimonials-dash-five__icon {
      height: 0;
      top: 0px; }
  .brk-testimonials-dash-five .swiper-slide-active {
    z-index: 2; }
    .brk-testimonials-dash-five .swiper-slide-active:before {
      content: "";
      position: absolute;
      left: -15px;
      top: -15px;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
      background-color: #ffffff; }
    .brk-testimonials-dash-five .swiper-slide-active:after {
      bottom: -15px;
      opacity: 1; }
    .brk-testimonials-dash-five .swiper-slide-active .brk-testimonials-dash-five__icon {
      height: 90px;
      top: -62px; }
  .brk-testimonials-dash-five__item {
    min-height: 452px;
    padding-top: 94px;
    transition: all .7s ease-in-out;
    background-color: var(--white);
    color: #546887;
    position: relative; }
  .brk-testimonials-dash-five__img {
    margin: 0 auto;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden; }
    .brk-testimonials-dash-five__img img {
      max-width: 100%;
      max-height: 100%; }
  .brk-testimonials-dash-five__name {
    font-size: 0.875rem;
    line-height: 24px;
    margin-top: 21px; }
    .brk-testimonials-dash-five__name span {
      color: var(--brand-primary); }
  .brk-testimonials-dash-five__desc {
    font-size: 0.875rem;
    line-height: 24px;
    margin: 8px auto 0;
    max-width: 320px;
    padding: 15px;
    max-height: 150px; }
  .brk-testimonials-dash-five__icon {
    position: absolute;
    left: 50%;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(40deg, var(--brand-primary) 0%, var(--secondary) 100%);
    border-radius: 100%;
    transform: translateX(-50%);
    transition: .3s ease all;
    overflow: hidden; }
    .brk-testimonials-dash-five__icon i {
      color: #fff;
      font-size: 32px; }

.brk-testimonials-dash-six {
  position: relative;
  padding: 38px 47px 35px;
  border: 2px solid #e8e8e8; }
  .brk-testimonials-dash-six__title-slider {
    font-size: 2.625rem;
    line-height: 48px;
    text-align: left;
    max-width: 200px;
    text-transform: uppercase;
    margin-bottom: 33px;
    padding-left: 5px; }
  .brk-testimonials-dash-six__item {
    text-align: left;
    max-width: 270px;
    padding-left: 5px; }
  .brk-testimonials-dash-six__comment {
    font-size: 1rem;
    line-height: 26px;
    margin-bottom: 27px;
    min-height: 78px;
    color: #868686; }
  .brk-testimonials-dash-six__photo {
    width: 100px;
    height: 100px;
    background: no-repeat center center;
    background-size: cover;
    position: relative; }
    .brk-testimonials-dash-six__photo:before {
      content: '';
      position: absolute;
      width: 94px;
      height: 94px;
      top: 3px;
      left: 3px;
      border: 3px solid var(--white); }
  .brk-testimonials-dash-six__name {
    letter-spacing: -.03em;
    margin-top: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    margin-top: 17px; }
  .brk-testimonials-dash-six__job {
    font-size: 0.875rem;
    line-height: 18px;
    color: #868686;
    display: flex;
    align-items: center; }
    .brk-testimonials-dash-six__job i {
      font-size: 9px;
      margin-right: 4px; }

.brk-testimonials-double {
  position: relative;
  z-index: 8; }
  .brk-testimonials-double__slider {
    margin-top: 35px;
    padding: 42px 40px 10px;
    min-height: 340px;
    background: var(--white) no-repeat center;
    background-size: cover;
    position: relative; }
    .brk-testimonials-double__slider:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1; }
    .brk-testimonials-double__slider.double-white:before {
      background-color: rgba(var(--white-rgb), 0.9); }
    .brk-testimonials-double__slider.double-white .brk-testimonials-double__type-reviews {
      background-color: var(--brand-primary);
      color: var(--white); }
    .brk-testimonials-double__slider.double-white .brk-testimonials-double__name span {
      color: #9f9f9f; }
    .brk-testimonials-double__slider.double-white .double-pagination-bullet:before {
      background-color: var(--brk-base-3); }
    .brk-testimonials-double__slider.double-white .double-pagination-bullet-active {
      border: 1px solid var(--brand-primary); }
    .brk-testimonials-double__slider.double-dark:before {
      background-color: rgba(var(--brand-primary-rgb), 0.9); }
    .brk-testimonials-double__slider.double-dark .brk-testimonials-double__type-reviews {
      background-color: var(--white);
      color: var(--brand-primary); }
    .brk-testimonials-double__slider.double-dark .brk-testimonials-double__title {
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark .brk-testimonials-double__name strong {
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark .brk-testimonials-double__name span {
      color: rgba(var(--white-rgb), 0.5); }
    .brk-testimonials-double__slider.double-dark .double-pagination-bullet:before {
      background-color: var(--white); }
    .brk-testimonials-double__slider.double-dark .double-pagination-bullet-active {
      border: 1px solid #fff; }
    .brk-testimonials-double__slider.double-dark .brk-rating__layer i {
      color: rgba(var(--white-rgb), 0.5); }
    .brk-testimonials-double__slider.double-dark .brk-rating__imposition i {
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark-blue:before {
      background-color: rgba(0, 2, 67, 0.78); }
    .brk-testimonials-double__slider.double-dark-blue .brk-testimonials-double__type-reviews {
      background-color: var(--white);
      color: var(--brand-primary); }
    .brk-testimonials-double__slider.double-dark-blue .brk-testimonials-double__title {
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark-blue .brk-testimonials-double__name strong {
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark-blue .brk-testimonials-double__name span {
      color: rgba(var(--white-rgb), 0.5); }
    .brk-testimonials-double__slider.double-dark-blue .double-pagination-bullet:before {
      background-color: var(--white); }
    .brk-testimonials-double__slider.double-dark-blue .double-pagination-bullet-active {
      border: 1px solid #fff; }
    .brk-testimonials-double__slider.double-dark-blue .brk-rating__layer i {
      color: rgba(var(--white-rgb), 0.5); }
    .brk-testimonials-double__slider.double-dark-blue .brk-rating__imposition i {
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark-light:before {
      background-color: rgba(0, 0, 0, 0.7); }
    .brk-testimonials-double__slider.double-dark-light .brk-testimonials-double__type-reviews {
      background-color: var(--brand-primary);
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark-light .brk-testimonials-double__title {
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark-light .brk-testimonials-double__name strong {
      color: var(--white); }
    .brk-testimonials-double__slider.double-dark-light .brk-testimonials-double__name span {
      color: rgba(var(--white-rgb), 0.5); }
    .brk-testimonials-double__slider.double-dark-light .double-pagination-bullet:before {
      background-color: var(--brk-base-3); }
    .brk-testimonials-double__slider.double-dark-light .double-pagination-bullet-active {
      border: 1px solid #dbdbdb; }
  .brk-testimonials-double__item {
    min-height: 240px;
    display: flex;
    flex-direction: column; }
  .brk-testimonials-double__type-reviews {
    position: relative;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    height: 32px;
    border-radius: 16px;
    text-align: center;
    font-size: 0.875rem;
    line-height: 32px;
    z-index: 2;
    display: inline-block; }
    .brk-testimonials-double__type-reviews_squared {
      border-radius: 0;
      font-size: 0.8125rem;
      height: 22px;
      line-height: 22px;
      min-width: 90px;
      font-weight: normal; }
  .brk-testimonials-double__title {
    font-size: 1.5rem;
    line-height: 32px;
    text-transform: uppercase;
    margin-top: 37px;
    padding-right: 5px;
    margin-bottom: 32px; }
  .brk-testimonials-double__contant {
    display: flex;
    align-items: center; }
  .brk-testimonials-double__photo {
    flex-basis: 86px;
    width: 86px;
    height: 86px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .brk-testimonials-double__name {
    flex-basis: calc(100% - 86px);
    width: calc(100% - 86px);
    padding-left: 25px;
    padding-top: 7px; }
    .brk-testimonials-double__name strong,
    .brk-testimonials-double__name span {
      display: block; }
    .brk-testimonials-double__name strong {
      margin-top: 16px;
      font-size: 1rem;
      line-height: 16px; }
    .brk-testimonials-double__name span {
      margin-top: 5px;
      font-size: 0.875rem;
      line-height: 14px; }

.brk-testimonials-layered-horizontal {
  min-height: 420px;
  background: no-repeat center;
  background-size: cover;
  position: relative;
  padding-bottom: 106px; }
  .brk-testimonials-layered-horizontal .before-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
  .brk-testimonials-layered-horizontal .after-layer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 104px;
    z-index: 1;
    background-color: var(--white); }
  .brk-testimonials-layered-horizontal svg {
    position: absolute;
    top: 103px;
    left: 0;
    right: 0;
    z-index: 2; }
  .brk-testimonials-layered-horizontal__container {
    max-width: 920px;
    margin: 0 auto;
    position: relative;
    z-index: 9; }
    .brk-testimonials-layered-horizontal__container .layered-horizontal-slider {
      width: calc(100% - 150px); }
      @media (max-width: 575px) {
        .brk-testimonials-layered-horizontal__container .layered-horizontal-slider {
          width: calc(100% - 50px); } }
    .brk-testimonials-layered-horizontal__container .swiper-base-arrow-md {
      top: 50%;
      transform: translateY(-50%);
      outline: none; }
      .brk-testimonials-layered-horizontal__container .swiper-base-arrow-md i {
        font-size: 1rem; }
      .brk-testimonials-layered-horizontal__container .swiper-base-arrow-md.button-prev {
        left: 4px;
        outline: none; }
      .brk-testimonials-layered-horizontal__container .swiper-base-arrow-md.button-next {
        right: 4px;
        outline: none; }
  .brk-testimonials-layered-horizontal__item {
    width: 100%;
    min-height: 420px;
    border-radius: 16px;
    position: relative;
    padding-top: 52px;
    box-shadow: 0 12px 31px rgba(0, 0, 0, 0.24);
    background-color: var(--white); }
    .brk-bordered-theme .brk-testimonials-layered-horizontal__item {
      border-radius: var(--b-radius); }
  .brk-testimonials-layered-horizontal .overlay-horizontal {
    position: absolute;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 12px 31px rgba(0, 0, 0, 0.24);
    border-radius: 16px;
    transition: all .8s ease;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8); }
    .brk-bordered-theme .brk-testimonials-layered-horizontal .overlay-horizontal {
      border-radius: var(--b-radius); }
    @media (max-width: 575px) {
      .brk-testimonials-layered-horizontal .overlay-horizontal {
        display: none; } }
    .brk-testimonials-layered-horizontal .overlay-horizontal.deactive {
      width: calc(100% - 400px);
      height: calc(100% - 400px);
      opacity: 0; }
  .brk-testimonials-layered-horizontal__photo {
    width: 119px;
    height: 119px;
    border-radius: 50%;
    box-shadow: 0 0 0 16px rgba(0, 0, 0, 0.02);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    position: relative; }
    .brk-testimonials-layered-horizontal__photo:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.06); }
  .brk-testimonials-layered-horizontal__name {
    font-size: 1.125rem;
    line-height: 18px;
    margin-top: 41px; }
  .brk-testimonials-layered-horizontal__job {
    font-size: 0.9375rem;
    line-height: 17px;
    margin-top: 6px;
    color: #9f9f9f; }
  .brk-testimonials-layered-horizontal__comment {
    font-size: 1rem;
    line-height: 26px;
    padding: 0 15px;
    max-width: 700px;
    margin: 28px auto 0;
    color: #9f9f9f; }
    @media (max-width: 767px) {
      .brk-testimonials-layered-horizontal__comment {
        padding: 0 15px 15px; } }

.brk-testimonials-layered-vertical {
  max-width: 970px;
  margin: 0 auto;
  position: relative;
  padding: 0 100px; }
  @media (max-width: 767px) {
    .brk-testimonials-layered-vertical {
      padding: 0 15px; } }
  .brk-testimonials-layered-vertical__container {
    max-width: 770px;
    padding: 40px 0; }
    @media (max-width: 767px) {
      .brk-testimonials-layered-vertical__container {
        padding: 100px 0 40px; } }
    .brk-testimonials-layered-vertical__container .swiper-base-arrow-vertical {
      top: 50%;
      transform: translateY(-50%);
      width: 64px;
      height: 72px; }
      @media (max-width: 767px) {
        .brk-testimonials-layered-vertical__container .swiper-base-arrow-vertical {
          top: 5px;
          transform: translateY(0); } }
      .brk-testimonials-layered-vertical__container .swiper-base-arrow-vertical i {
        font-size: 4rem;
        line-height: 64px;
        color: var(--brand-primary); }
      .brk-testimonials-layered-vertical__container .swiper-base-arrow-vertical.button-prev {
        left: 19px;
        outline: none; }
      .brk-testimonials-layered-vertical__container .swiper-base-arrow-vertical.button-next {
        right: 19px;
        outline: none; }
  .brk-testimonials-layered-vertical .overlay-vertical {
    position: absolute;
    width: calc(100% - 280px);
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 12px 31px rgba(0, 0, 0, 0.24);
    border-radius: 16px;
    transition: all .8s ease-in-out;
    z-index: 1;
    background-color: var(--white); }
    @media (max-width: 767px) {
      .brk-testimonials-layered-vertical .overlay-vertical {
        display: none; } }
    .brk-testimonials-layered-vertical .overlay-vertical.deactive {
      width: 100px;
      height: 100px;
      opacity: 0; }
  .brk-testimonials-layered-vertical .swiper-wrapper {
    height: 370px; }
    @media (max-width: 767px) {
      .brk-testimonials-layered-vertical .swiper-wrapper {
        height: 500px; } }
    @media (max-width: 575px) {
      .brk-testimonials-layered-vertical .swiper-wrapper {
        height: 540px; } }
    @media (max-width: 375px) {
      .brk-testimonials-layered-vertical .swiper-wrapper {
        height: 580px; } }
  .brk-testimonials-layered-vertical__item {
    width: 100%;
    height: 100%;
    min-height: 370px;
    border-radius: 16px;
    position: relative;
    text-align: left;
    box-shadow: 0 12px 31px rgba(0, 0, 0, 0.24);
    background-color: var(--white); }
  .brk-testimonials-layered-vertical__person {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #f4f4f4; }
    @media (max-width: 767px) {
      .brk-testimonials-layered-vertical__person {
        flex-direction: column; } }
    .brk-testimonials-layered-vertical__person-info {
      flex-basis: 60%;
      width: 60%;
      padding-left: 41px; }
      .brk-testimonials-layered-vertical__person-info:before, .brk-testimonials-layered-vertical__person-info:after {
        content: " ";
        display: table; }
      .brk-testimonials-layered-vertical__person-info:after {
        clear: both; }
      @media (max-width: 767px) {
        .brk-testimonials-layered-vertical__person-info {
          flex-basis: 100%;
          width: 100%;
          padding-left: 0;
          text-align: center; } }
  .brk-testimonials-layered-vertical__photo {
    width: 91px;
    height: 91px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-right: 23px;
    box-shadow: 0 0 0 3px #f0f0f0;
    float: left; }
    @media (max-width: 767px) {
      .brk-testimonials-layered-vertical__photo {
        float: none;
        margin: 0 auto; } }
  .brk-testimonials-layered-vertical__name {
    font-size: 1.3125rem;
    line-height: 21px;
    margin-top: 27px; }
  .brk-testimonials-layered-vertical__job {
    font-size: 0.9375rem;
    line-height: 17px;
    margin-top: 5px;
    color: #9f9f9f; }
  .brk-testimonials-layered-vertical__comment {
    font-size: 1rem;
    line-height: 26px;
    padding: 36px 15px 15px;
    max-width: 700px;
    margin: 0 auto;
    color: #9f9f9f; }
    @media (max-width: 767px) {
      .brk-testimonials-layered-vertical__comment {
        text-align: center; } }
  .brk-testimonials-layered-vertical .brk-rating {
    right: 40px;
    top: 6px; }
    @media (max-width: 767px) {
      .brk-testimonials-layered-vertical .brk-rating {
        right: auto;
        top: 15px; } }

.brk-testimonials-circle {
  height: 700px;
  position: relative;
  margin-bottom: 45px;
  text-align: center; }
  @media (max-width: 575px) {
    .brk-testimonials-circle {
      height: 500px; } }
  .brk-testimonials-circle:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    z-index: 9;
    border-top: 15px solid var(--white); }
  .brk-testimonials-circle .circle-slider {
    width: 100%;
    height: 100%; }
  .brk-testimonials-circle__parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    .brk-testimonials-circle__parallax-bg:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7); }
  .brk-testimonials-circle__item {
    position: absolute;
    top: calc(50% - 20px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 15px;
    max-width: 800px;
    color: var(--white); }
    .brk-testimonials-circle__item i {
      font-size: 2.75rem;
      line-height: 30px;
      margin-bottom: 45px;
      color: rgba(var(--white-rgb), 0.3); }
  .brk-testimonials-circle__comment {
    font-size: 1.625rem;
    line-height: 44px;
    letter-spacing: -.025em; }
    @media (max-width: 575px) {
      .brk-testimonials-circle__comment {
        font-size: 1rem;
        line-height: 28px; } }
  .brk-testimonials-circle__name {
    font-size: 0.75rem;
    line-height: 14px;
    margin-top: 40px;
    letter-spacing: .03em; }
    .brk-testimonials-circle__name span {
      text-transform: uppercase; }
  .brk-testimonials-circle .circle-pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(50%);
    width: 100%;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99; }
    .brk-testimonials-circle .circle-pagination-bullet {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin: 0 25px;
      background: no-repeat center center;
      background-size: cover;
      cursor: pointer;
      transition: all .4s ease-in-out;
      border: 2px solid var(--white);
      outline: none; }
      @media (max-width: 575px) {
        .brk-testimonials-circle .circle-pagination-bullet {
          width: 30px;
          height: 30px;
          margin: 0 8px; } }
      .brk-testimonials-circle .circle-pagination-bullet:hover, .brk-testimonials-circle .circle-pagination-bullet-active {
        transform: scale(1.66667);
        border-width: 1px; }
        @media (max-width: 575px) {
          .brk-testimonials-circle .circle-pagination-bullet:hover, .brk-testimonials-circle .circle-pagination-bullet-active {
            transform: scale(1.4); } }

.swiper-pagination-base {
  margin: 43px auto 0;
  transform: none !important;
  display: flex;
  width: 100px; }
  .swiper-pagination-base_lite-white .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    background: #fff;
    opacity: 1; }
  .swiper-pagination-base .swiper-pagination-bullet {
    flex-basis: 8px;
    min-width: 8px;
    height: 8px;
    outline: none; }

.dash-six-arrow {
  width: 68px;
  height: 68px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  z-index: 9;
  background-color: var(--brand-primary);
  color: var(--white); }
  .dash-six-arrow-prev, .dash-six-arrow-next {
    position: absolute;
    width: 50%;
    height: 100%;
    cursor: pointer;
    outline: none; }
    .dash-six-arrow-prev i, .dash-six-arrow-next i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-top: -1px; }
  .dash-six-arrow-prev {
    left: 0; }
    .dash-six-arrow-prev i {
      right: 7px; }
  .dash-six-arrow-next {
    right: 0; }
    .dash-six-arrow-next i {
      left: 7px; }

.double-pagination {
  position: absolute;
  top: 54px;
  right: 33px;
  display: flex;
  z-index: 3; }
  .double-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid transparent;
    position: relative;
    margin: 0px 7px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    outline: none; }
    .double-pagination-bullet:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s ease-in-out; }
    .double-pagination-bullet-active:before {
      opacity: 0; }

.swiper-pagination-bullet-active {
  background-color: var(--brand-primary); }
