// Custom Mixins
// --------------------------------------------------
// Utilities
@import "mixins/helpers";
@import "mixins/vendors-prefix";
@import "mixins/flex";
@import "mixins/text-utilities";
@import "mixins/pull-mixins";
@import "mixins/show-hide";
@import "mixins/responsive-generator";
@import "mixins/group";
@import "mixins/media-mixin";
@import "mixins/functions";
@import "mixins/backgrounds";
@import "mixins/bem";
// Grid Framework
@import "mixins/custom-grid";

@mixin clearfix() {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
}

@mixin background-dotted($bg-color, $dot-color, $dot-size, $dot-space) {
	background: linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
	linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
	$dot-color;
	background-size: $dot-space $dot-space;
}


@function breakpoint-min($name, $breakpoints: $brk-grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
	@return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $brk-grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}